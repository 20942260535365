import React, { useContext, useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import secureLocalStorage from "react-secure-storage";
import CircularProgress from "@mui/material/CircularProgress";
import RecentFiles from "../components/RecentFiles";
import { useParams } from "react-router-dom";
import { api } from "../utils/axios-instance";
import { useDarkMode } from "../context/darkModeContext";
import useAuth from "../stores/authStore";
import fileContext from "../context/fileContext";
import MultipleFileMenu from "../components/MultipleFileMenu";
import RecentDeletedFiles from "../components/RecentDeletedFiles";

const RecycleBin = () => {
  const [filesFromBackend, setFilesFromBackend] = useState([]);
  const [loading, setLoading] = useState(true);
  const [recycleFiles, setRecycleFiles] = useState([]);

  const [select, setSelect] = useState(false);
  const [showMultiFileOptions, setShowMultiFileOptions] = useState(false);
  const context = useContext(fileContext);
  const {
    departmentFiles,
    setDepartmentFiles,
    refreshRecycleBin,
    setRefreshRecycleBin,
  } = context;

  const profileData = useAuth((state) => state.profileData);
  useEffect(() => {
    setFilesFromBackend(departmentFiles);
  }, [departmentFiles]);

  const removeFiles = () => {
    const idsToRemove = context.selectedFiles.map((file) => file.id);

    const updatedFiles = filesFromBackend.filter(
      (file) => !idsToRemove.includes(file.id)
    );

    setFilesFromBackend(updatedFiles);
  };

  const updateFiles = (file, val) => {
    setFilesFromBackend((prevData) =>
      prevData.map((data) =>
        data.id === file.id ? { ...data, isLocked: val } : data
      )
    );
  };

  useEffect(() => {
    setLoading(true);
    const fetchRecycleBinData = async () => {
      try {
        const response = await api.get("/file/recycle-bin-file");

        console.log("Recycle Bin Files:", response.data);
        if (response && response.data) {
          const deletedFiles = response.data;
          const filteredFiles = deletedFiles.filter(
            (file) =>
              file.owner === profileData.id ||
              profileData.role_priv === "org_admin"
          );
          setRecycleFiles(filteredFiles);
        }
        setRefreshRecycleBin(false);
      } catch (error) {
        console.error("Error fetching recycle bin files:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRecycleBinData();
  }, [refreshRecycleBin, setRefreshRecycleBin]);
  console.log("Recycle Files:yare ", { recycleFiles });
  return (
    <div>
      <p className="text-2xl font-semibold m-2">Recycle Bin</p>
      <p className="ml-2">Files present: {recycleFiles.length}</p>

      <span className="flex gap-2">
        {showMultiFileOptions && (
          <MultipleFileMenu
            removeMultiSelect={() => setSelect(false)}
          // location="department"
          // deptName={deptName}
          />
        )}
      </span>

      <div className="px-4">
        <RecentDeletedFiles
          recycleFiles={recycleFiles}
          loading={loading}
          select={select}
          setSelect={setSelect}
          showMultiFileOptions={showMultiFileOptions}
          setShowMultiFileOptions={setShowMultiFileOptions}
        />
      </div>
    </div>
  );
};

export default RecycleBin;
