import React, { useState, useEffect, useCallback } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import secureLocalStorage from "react-secure-storage";
import { auth, authStateKnown } from "../helper/firebaseClient";
import CircularProgress from "@mui/material/CircularProgress";
import useAuth from "../stores/authStore";
import Loading from "../components/Loading";
import toast, { Toaster } from "react-hot-toast";
import { Dropdown, MenuButton } from "@mui/joy";
import { Menu } from "@mui/material";
import { api } from "../utils/axios-instance";
import { useDepartment } from "../stores/departmentStore";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  width: "60vw",
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "white",
  border: `1px solid #fff`,
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const MAX_PROFILEPHOTO_SIZE = 1024 * 1024 * 2;
const Onboard = () => {
  const [expanded, setExpanded] = useState("panel1");
  const [formData, setFormData] = useState({
    username: "",
    department: "",
    firstName: "",
    lastName: "",
    profileUrl: "",
    profilePicture: null,
  });

  const [isPictureSelected, setIsPictureSelected] = useState(false);
  //   const [departmentList, setDepartmentList] = useState([]);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  let token = secureLocalStorage.getItem("token");
  const profileData = useAuth((state) => state.profileData);
  const setProfileData = useAuth((state) => state.setProfileData);
  const departments = useDepartment((state) => state.departments);
  const navigate = useNavigate();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const isFileSizeInLimit = (file) => {
    if (file && file.size <= MAX_PROFILEPHOTO_SIZE) {
      return true;
    }
    return false;
  };
  const handleInputChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      if (!isFileSizeInLimit(file)) {
        toast.error("File size is greater than allowed limit!");
        return;
      }
      setFormData({
        ...formData,
        profilePicture: file,
      });
      setIsPictureSelected(true);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
    maxFiles: 1,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("formData", formData);
  };

  useEffect(() => {
    checkFormCompletion();
  }, [formData]);

  const checkFormCompletion = () => {
    const { username, firstName, lastName, department, profilePicture } =
      formData;

    // Check if all required fields are filled
    if (
      username.trim() !== "" &&
      firstName.trim() !== "" &&
      lastName.trim() !== "" &&
      department.trim() !== "" &&
      profilePicture !== null
    ) {
      setIsFormComplete(true);
    } else {
      setIsFormComplete(false);
    }
  };

  const handleLogout = useCallback(async () => {
    // change the active status
    const user = await authStateKnown;
    if (!user) {
      toast("User not found");
      return;
    }
    const token = await user.getIdToken();
    let body = {
      idToken: token,
      is_active: false,
    };

    try {
      await api.put(`/auth/logout/`, body);
      secureLocalStorage.removeItem("profileData");
      secureLocalStorage.removeItem("token");
      auth.signOut().then(() => {
        console.log("Logged out");
        navigate("/");
      });
    } catch (error) {
      console.log(error);
      toast.error(error?.message || "Something went wrong");
    }
  }, [navigate]);

  const handleNextButtonClick = async () => {
    setLoading(true);
    console.log(formData);
    const user = auth.currentUser;

    if (!user) {
      throw new Error("User not authenticated");
    }

    const idToken = await user.getIdToken();

    try {
      let profilePictureBase64 = null;
      const profilePictureFile = formData.profilePicture;
      if (profilePictureFile) {
        // Convert the image file to base64
        const reader = new FileReader();
        reader.readAsDataURL(profilePictureFile);
        await new Promise((resolve) => {
          reader.onloadend = () => {
            profilePictureBase64 = reader.result.split(",")[1];
            resolve();
          };
        });
      }

      let newProfileData = {
        id: user.uid,
        username: formData.username,
        name: formData.firstName,
        last_name: formData.lastName,
        dept: formData.department,
      };

      try {
        const res = await axios.put(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/users/update-profile/`,
          {
            idToken,
            profileData: newProfileData,
            profilePicture: profilePictureBase64,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );

        console.log("onboarding success:", res);
        secureLocalStorage.setItem("profileData", JSON.stringify(res.data));
        if (res) {
          setProfileData((prevData)=>({
            ...prevData,
            ...res.data,
          }));
          setLoading(false);
          navigate("/waiting-lobby");
        }
      } catch (error) {
        setLoading(false);
        console.log("Error at  update profile:", error);
      }
    } catch (error) {
      setLoading(false);
      console.log("error occured at onboard", error);
    }
  };

  if (!profileData || !profileData?.is_authenticated) {
    return <Navigate to={"/login"} />;
  }
  console.log("profileData", profileData);
  return (
    <div className="min-h-screen flex flex-col justify-between items-center">
      <div className="flex items-center justify-between w-full  px-2 md:px-4 lg:px-8 py-4 border-b-2  ">
        <a href="/">
          <div className="flex items-center space-x-2">
            <img
              src="/twokey-logo.svg"
              alt="Logo"
              className="h-6 w-6 text-[#1E1B66]"
            />
            <span className="text-xl font-allertaStencil text-[#1E1B66]">
              TWOKEY
            </span>
          </div>
        </a>
        <div className="flex justify-end items-center gap-8">
          <button
            onClick={handleLogout}
            className="bg-red-500 rounded-md px-4 py-1 w-full text-white hover:bg-red-400 duration-200 shadow-sm"
          >
            Logout
          </button>
        </div>
      </div>
      <Toaster position="bottom-left" reverseOrder={false} />

      <div className="text-center p-16">
        <strong className="text-5xl font-raleway">Welcome to TwoKey</strong>
        <p className="text-lg text-gray-400 my-2">
          A secured file sharing platform for companies
        </p>
        <form className="my-16 shadow-lg rounded-lg" onSubmit={handleSubmit}>
          <div>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography variant="p" className="text-lg font-semibold">
                  General Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4 text-left">
                  <div className="mb-4">
                    <label className="block text-gray-600 text-sm font-medium p-1">
                      Profile Picture{" "}
                      <span className="text-xs text-red-500">
                        up to {MAX_PROFILEPHOTO_SIZE / 1024 / 1024} MBs
                      </span>
                    </label>
                    {isPictureSelected ? (
                      <img
                        src={URL.createObjectURL(formData.profilePicture)}
                        alt="Profile Pic"
                        className="mt-2 max-h-40 max-w-full rounded-md"
                      />
                    ) : (
                      <div
                        {...getRootProps()}
                        className="mt-2 h-28 w-32 hover:text-blue-400 flex items-center justify-center border-2 border-dashed hover:border-blue-400 border-gray-400 p-4 rounded-md text-center cursor-pointer"
                      >
                        {/* accept only images */}
                        <input {...getInputProps()} accept="image/*" />
                        <p>Drop files to upload</p>
                      </div>
                    )}
                  </div>
                  <div></div>
                  <div className="mb-4">
                    <label className="block text-gray-600 text-sm font-medium p-1">
                      Username
                    </label>
                    <input
                      type="text"
                      className="block w-full border rounded-md py-2 px-3"
                      placeholder="Enter your username"
                      value={formData.username}
                      onChange={(e) =>
                        handleInputChange("username", e.target.value)
                      }
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-600 text-sm font-medium p-1">
                      Department
                    </label>
                    {departments?.length && (
                      <Select
                        className="w-full bg-gray-100"
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={formData.department}
                        label="Departments"
                        name="Departments"
                        onChange={(e) =>
                          handleInputChange("department", e.target.value)
                        }
                        size="small"
                      >
                        {departments.map((dept) => (
                          <MenuItem
                            // style={{ backgroundColor: dept?.metadata?.bg }}
                            key={dept.id}
                            value={dept.id}
                          >
                            <span
                              className="w-4 h-4 rounded-full inline-block mr-2"
                              style={{
                                backgroundColor: dept?.metadata?.bg,
                              }}
                            ></span>
                            {dept.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-600 text-sm font-medium p-1">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="block w-full border rounded-md py-2 px-3"
                      placeholder="Enter your first name"
                      value={formData.firstName}
                      onChange={(e) =>
                        handleInputChange("firstName", e.target.value)
                      }
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-600 text-sm font-medium p-1">
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="block w-full border rounded-md py-2 px-3"
                      placeholder="Enter your last name"
                      value={formData.lastName}
                      onChange={(e) =>
                        handleInputChange("lastName", e.target.value)
                      }
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </form>
      </div>
      <footer className="fixed bottom-0 left-0 w-full py-4 text-right p-16 bg-gray-50 bg-opacity-10 backdrop-blur-sm flex justify-between items-center border">
        <Typography variant="p" className="text-sm text-gray-400">
          TwoKey © 2023
        </Typography>
        <button
          type="submit"
          onClick={() => handleNextButtonClick()}
          className={`rounded-md py-2 px-8 text-white bg-blue-700 ${
            (!isFormComplete || loading) && "opacity-20 cursor-not-allowed"
          }`}
          disabled={!isFormComplete || loading}
        >
          {loading ? (
            <CircularProgress
              style={{ color: "white", height: 25, width: 25 }}
            />
          ) : (
            "Next"
          )}
        </button>
      </footer>
    </div>
  );
};

export default Onboard;
